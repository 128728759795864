import { Link } from "react-router-dom";
import { GiWallet } from "react-icons/gi";
import { FaUserLarge } from "react-icons/fa6";
import "./Navbar.css"; // Import the CSS file
import Logout from "../../../Auth/Logout/Logout";
import companyLogo from "../../../../Assets/logos/VIGHNAAORESS.png"

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img src={companyLogo} alt="logo" />
      </div>
      <ul className="navbar-links">
        <li>
          <GiWallet />
        </li>
        <li>
          <Logout />
        </li>
        <li>
          <Link to="/organizationInfo">
            <FaUserLarge  />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
