import React from "react";
import { Link } from "react-router-dom";


const DashboardSidenav = () => {
  return (
    <div style={{ overflow: "auto" }}>
      <nav className="sidenav">
        <ul>
          <Link to={"/freelancer"}>
            <li>Projects</li>
          </Link>

          <Link to={"/paypal-subscription"}>
            <li>Payments</li>
          </Link>

          <Link to={"/chat"}>
            <li>Support/Help center</li>
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default DashboardSidenav;
