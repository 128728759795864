import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';

const SelectDropdown = ({label, options,register, error, placeholder  }) => {



  return (
    <div className="form-group">
       <FormControl fullWidth error={!!error}>
       <InputLabel>{label}</InputLabel>
       <Select
        {...register}
        defaultValue=""
        displayEmpty
        label={label}
        style={{ width: '250px' }}
      >
        <MenuItem value="" >
          <em>{placeholder || `Select a ${label}`}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error.message}</FormHelperText>}
      
</FormControl>
    </div>
  )
}

export default SelectDropdown