import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const InvoicePage = () => {
    const { projectId } = useParams(); // Get project ID from URL
    const location = useLocation();
    const project = location.state?.project; // Get project details from state
    const freelancerName=localStorage.getItem("username");
    const [clientName, setClientName] = useState("");
    const [email, setEmail] = useState("");

    const generatePDF = () => {
        const input = document.getElementById("invoice");
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/png");
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save(`invoice_${projectId}.pdf`);
        });
    };

    if (!project) return <h2 style={{ textAlign: "center", marginTop: "20px" }}>Loading invoice...</h2>;

    return (
        <div style={styles.pageContainer}>
            <div id="invoice" style={styles.invoiceContainer}>
                <h2 style={styles.header}>Invoice</h2>
                {/* <div style={styles.infoSection}>
                    <label style={styles.label}>Client Name:</label>
                    <input
                        type="text"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        placeholder="Enter Client Name"
                        style={styles.input}
                    />
                </div>
                <div style={styles.infoSection}>
                    <label style={styles.label}>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Client Email"
                        style={styles.input}
                    />
                </div> */}
                <div style={styles.projectDetails}>
                <p><strong>Freelancer Name:</strong> {freelancerName}</p>
                    <p><strong>Project Title:</strong> {project.projectName}</p>
                    <p><strong>Amount:</strong> ${project.amount}</p>
                </div>
            </div>
            <button onClick={generatePDF} style={styles.downloadButton}>Download PDF</button>
        </div>
    );
};

// 🎨 **Styles**
const styles = {
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f4f4f9",
    },
    invoiceContainer: {
        backgroundColor: "#ffffff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        width: "400px",
        textAlign: "center",
        border: "3px solid #4A90E2",
    },
    header: {
        backgroundColor: "#4A90E2",
        color: "#fff",
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "15px",
    },
    infoSection: {
        marginBottom: "15px",
    },
    label: {
        display: "block",
        fontWeight: "bold",
        marginBottom: "5px",
        color: "#333",
    },
    input: {
        width: "100%",
        padding: "8px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        fontSize: "16px",
    },
    projectDetails: {
        backgroundColor: "#F0F8FF",
        padding: "10px",
        borderRadius: "5px",
        border: "1px solid #4A90E2",
    },
    downloadButton: {
        marginTop: "20px",
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#4A90E2",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "0.3s",
    },
};

export default InvoicePage;
