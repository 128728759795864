import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const useAutoLogin = (setAuthLogin) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("useEffect runs");
      try {
        const decodedToken = jwtDecode(token);
        const role = decodedToken.userId.role;
        const organizationId = decodedToken.userId.organizationId;

        setAuthLogin(token, role);

        if (role === "organization" && organizationId) {
          localStorage.setItem("organizationId", organizationId);
        }

        // Navigate based on role
        if (role === "freelancer") {
          navigate("/freelancer", { replace: true });
        } else if (role === "organization") {
          navigate("/home", { replace: true });
        } else if (role === "admin") {
          navigate("/selection", { replace: true });
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
      }
    }
  }, [setAuthLogin, navigate]);
};

export default useAutoLogin;
