import React, { useEffect, useState, useRef } from "react";
import socket from "../../Socket/socket";

const Receive = () => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]); // Stores both sent & received messages
  const currentUserId = "456"; // Receiver user ID
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Register the user ID when the component mounts
    socket.emit("register", currentUserId);

    // Listen for incoming messages
    socket.on("chat", (message) => {
      console.log("Message received:", message);
      setChatMessages((prev) => [...prev, message]); // Add received message to chat
    });

    return () => {
      socket.off("chat"); // Cleanup listener on unmount
    };
  }, []);

  useEffect(() => {
    // Auto-scroll to the latest message
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const sendMessage = () => {
    if (!message.trim()) return; // Prevent sending empty messages

    const newMessage = {
      senderId: currentUserId,
      receiverId: "123", // Sending back to the original sender
      messageText: message,
    };

    socket.emit("chat", newMessage);
    setChatMessages((prev) => [...prev, newMessage]); // Add sent message to chat immediately
    setMessage(""); // Clear input after sending
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Receive Component (Chat)</h2>

      {/* Chat Messages Container */}
      <div ref={chatContainerRef} style={styles.chatBox}>
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            style={{
              ...styles.messageBubble,
              alignSelf: msg.senderId === currentUserId ? "flex-end" : "flex-start",
              backgroundColor: msg.senderId === currentUserId ? "#0084FF" : "#E5E5EA",
              color: msg.senderId === currentUserId ? "#fff" : "#000",
            }}
          >
            {msg.messageText}
          </div>
        ))}
      </div>

      {/* Message Input */}
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          style={styles.input}
        />
        <button onClick={sendMessage} style={styles.sendButton}>
          Reply
        </button>
      </div>
    </div>
  );
};

export default Receive;

// Styles for chat layout
const styles = {
  container: {
    width: "400px",
    margin: "auto",
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
  },
  header: {
    textAlign: "center",
    marginBottom: "10px",
  },
  chatBox: {
    display: "flex",
    flexDirection: "column",
    height: "300px",
    overflowY: "auto",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  messageBubble: {
    maxWidth: "70%",
    padding: "10px",
    borderRadius: "15px",
    marginBottom: "10px",
  },
  inputContainer: {
    display: "flex",
    marginTop: "10px",
  },
  input: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
  },
  sendButton: {
    padding: "10px 15px",
    marginLeft: "5px",
    backgroundColor: "#0084FF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
