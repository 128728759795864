import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  BrowserRouter as Router,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Signup from "./Pages/Auth/signup/Signup";
import Login from "./Pages/Auth/Login/Login";
import Home from "./Pages/Home/Home";
import Organization from "./Pages/Organization/Organization";
import React, { useContext } from "react";
import AuthProvider, { AuthContext } from "./Context/AuthContext";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import OrganizationPage from "./Pages/Auth/OrganizationRegisterPage/OrganizationPage";
import OrganizationInfo from "./Pages/Organization/OrganizationInfo";
import Chat from "./Pages/Chat/Chat";
import Receive from "./Pages/Chat/Receive";
import UserProfile from "./Pages/Home/Freelancer/Profile/UserProfile";
import Invoice from "./Pages/Home/Freelancer/Invoice/Invoice";
import WalletPage from "./Pages/Home/Freelancer/Wallet/WalletPage";
import ForgotPassword from "./Pages/Auth/ForgotPassword/ForgotPassword";
import VerifyOtp from "./Pages/Auth/VerifyOTP/VerifyOtp";
import ResetPassword from "./Pages/Auth/ResetPassword/ResetPassword";
import PaymentPlans from "./Pages/Payment/PaymentPlans/PaymentPlans";
import Freelancer from "./Pages/Home/Freelancer/Freelancer";
import PayPalSubscriptionButton from "./Pages/Payment/Paypal/Paypal";

// Layout Component
const AppLayout = () => {
  return (
    <div>
      <Outlet />

      {/* Renders nested routes */}
    </div>
  );
};
const App = () => {
  const { user } = useContext(AuthContext); // Move useContext inside a component

  const appRouter = createBrowserRouter([
    {
      element: <AppLayout />, // Wrapper for shared layouts
      children: [
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/signup",
          element: <Signup />,
        },
        {
          path: "/signin",
          element: <Login />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/verify-otp",
          element: <VerifyOtp />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/payment-plans",
          element: <PaymentPlans />,
        },
        {
          path: "/paypal-subscription",
          element: <PayPalSubscriptionButton/>,
        },
        {
          path: "/chat",
          element: <Chat />,
        },
        {
          path: "/Receive",
          element: <Receive />,
        },
        {
          path: "/userprofile",
          element: <UserProfile />,
        },
        {
          path: "/invoice/:projectId",
          element: <Invoice />,
        },
        {
          path: "/wallet",
          element: <WalletPage />,
        },
        {
          path: "/organizationRegister/:userId",
          element: <OrganizationPage />,
        },
        {
          path: "/organizationInfo",
          element: <OrganizationInfo />,
        },

        {
          path: "/freelancer",
          element: <Freelancer />,
        },
        {
          path: "/organization",
          element: <Organization />,
        },

        {
          path: "*",
          element: <Login />, // Fallback route
        },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
};

// Mount the App
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

export default App;
