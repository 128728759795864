// import React, { useState } from "react";
// import "../Freelancer.css"

// const CurrencyDropdownButton = () => {
//   const [selectedCurrency, setSelectedCurrency] = useState("");

//   const currencies = [
//     { label: "India - INR", value: "INR" },
//     { label: "United States - USD", value: "USD" },
//     { label: "United Kingdom - GBP", value: "GBP" },
//     { label: "European Union - EUR", value: "EUR" },
//     { label: "Japan - JPY", value: "JPY" },
//     { label: "China - CNY", value: "CNY" },
//     { label: "Canada - CAD", value: "CAD" },
//     { label: "Australia - AUD", value: "AUD" },
//     { label: "Russia - RUB", value: "RUB" },
//     { label: "Brazil - BRL", value: "BRL" },
//     { label: "South Africa - ZAR", value: "ZAR" },
//     { label: "Switzerland - CHF", value: "CHF" },
//     { label: "Saudi Arabia - SAR", value: "SAR" },
//     { label: "United Arab Emirates - AED", value: "AED" },
//     { label: "Singapore - SGD", value: "SGD" },
//     { label: "Malaysia - MYR", value: "MYR" },
//     { label: "Indonesia - IDR", value: "IDR" },
//     { label: "South Korea - KRW", value: "KRW" },
//     { label: "Mexico - MXN", value: "MXN" },
//     { label: "Turkey - TRY", value: "TRY" },
//     { label: "Thailand - THB", value: "THB" },
//     { label: "Vietnam - VND", value: "VND" },
//     { label: "Egypt - EGP", value: "EGP" },
//     { label: "Argentina - ARS", value: "ARS" },
//     { label: "New Zealand - NZD", value: "NZD" },
//   ];

//   const handleChange = (event) => {
//     setSelectedCurrency(event.target.value);
//   };

//   return (
//     <div className="dropdown-container">
//       <select className="currency-dropdown" value={selectedCurrency} onChange={handleChange}>
//         <option value="">Select a currency</option>
//         {currencies.map((currency) => (
//           <option key={currency.value} value={currency.value}>
//             {currency.label}
//           </option>
//         ))}
//       </select>
//       {selectedCurrency && <p>Selected Currency: {selectedCurrency}</p>}
//     </div>
//   );
// };

// export default CurrencyDropdownButton ;
import React, { useState, useEffect } from "react";
import axios from "axios";

const CurrencyDropdownButton = () => {
  const [amount, setAmount] = useState(1);
  const [fromCurrency, setFromCurrency] = useState("USD");
  const [toCurrency, setToCurrency] = useState("INR");
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [rates, setRates] = useState({});

  useEffect(() => {
    fetchRates();
  }, [fromCurrency, toCurrency]);

  const fetchRates = async () => {
    try {
      const response = await axios.get(`
        https://api.exchangerate-api.com/v4/latest/${fromCurrency}`
      );
      setRates(response.data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates", error);
    }
  };

  const convert = () => {
    if (rates[toCurrency]) {
      setConvertedAmount((amount * rates[toCurrency]).toFixed(2));
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-md mx-auto">
      
      <div className="flex flex-col gap-4">
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
        />
        <select
          className="border p-2 rounded"
          value={fromCurrency}
          onChange={(e) => setFromCurrency(e.target.value)}
        >
          {Object.keys(rates).map((currency) => (
            <option key={currency} value={currency}>{currency}</option>
          ))}
        </select>
        <select
          className="border p-2 rounded"
          value={toCurrency}
          onChange={(e) => setToCurrency(e.target.value)}
        >
          {Object.keys(rates).map((currency) => (
            <option key={currency} value={currency}>{currency}</option>
          ))}
        </select>
        <button onClick={convert}>Convert</button>
        {convertedAmount && (
          <p className="text-lg font-semibold">Converted Amount: {convertedAmount} {toCurrency}</p>
        )}
      </div>
    </div>
  );
};

export default CurrencyDropdownButton;