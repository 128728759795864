import React, { useEffect, useState } from 'react';
import './ProjectCard.css'; // Import the CSS file

const ProjectCard = ({ project }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  useEffect(() => {
    const cards = document.querySelectorAll('.project-card');
    cards.forEach((card, index) => {
      card.style.opacity = 0;
      card.style.transform = 'translateY(30px)';
      setTimeout(() => {
        card.style.transition = 'opacity 0.6s ease-out, transform 0.6s ease-out';
        card.style.opacity = 1;
        card.style.transform = 'translateY(0)';
      }, index * 150);
    });
  }, []);

  // Toggle the visibility of the description
  const toggleDescription = () => {
    setShowFullDescription((prevState) => !prevState);
  };

  return (
    <div className="project-card">
      <div className="card-content">
        <h3 className="project-title">{project.projectName}</h3>
        <p className="project-description ">
        <i style={{color:'blue'}}>Description: </i> <b style={{color:"black"}}>{showFullDescription
            ? project.projectDescription
            : project.projectDescription.slice(0, 50)}</b>
        </p>
        {project.projectDescription.length > 50 && (
          <button className="read-more-btn" onClick={toggleDescription}>
            {showFullDescription ? "Show Less" : "Read More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
