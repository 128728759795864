// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  text-align: center;
  position: absolute;
  top: 60px; /* distance from icon */
  right: 0;
  background-color:#92c7cf;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  height: 50px;
  padding: 12px;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeSlide 0.3s forwards;
}

.username {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}

/* .dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeSlide 0.3s forwards;
} */

@keyframes fadeSlide {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Freelancer/UserDropdown/userDropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS,EAAE,uBAAuB;EAClC,QAAQ;EACR,wBAAwB;EACxB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,0CAA0C;EAC1C,aAAa;EACb,YAAY;EACZ,aAAa;EACb,UAAU;EACV,4BAA4B;EAC5B,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;;;;GAIG;;AAEH;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":[".user-dropdown {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.dropdown-menu {\r\n  text-align: center;\r\n  position: absolute;\r\n  top: 60px; /* distance from icon */\r\n  right: 0;\r\n  background-color:#92c7cf;\r\n  border: 1px solid #ddd;\r\n  border-radius: 8px;\r\n  width: auto;\r\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);\r\n  z-index: 1000;\r\n  height: 50px;\r\n  padding: 12px;\r\n  opacity: 0;\r\n  transform: translateY(-10px);\r\n  animation: fadeSlide 0.3s forwards;\r\n}\r\n\r\n.username {\r\n  font-weight: bold;\r\n  border-bottom: 1px solid #eee;\r\n  font-size: 12px;\r\n}\r\n\r\n/* .dropdown-menu {\r\n  opacity: 0;\r\n  transform: translateY(-10px);\r\n  animation: fadeSlide 0.3s forwards;\r\n} */\r\n\r\n@keyframes fadeSlide {\r\n  to {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
