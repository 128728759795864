import React, { useEffect, useState } from "react";
import useWallet from "../../../../utils/Hooks/useWallet";
import { FaWallet } from "react-icons/fa"; // Wallet icon
import "./WalletStyles.css";
const WalletPage = () => {
  const freelancerId = localStorage.getItem("freelancerId");
  const { walletBalance, loading, error, fetchWalletBalance } = useWallet();
  const [animatedBalance, setAnimatedBalance] = useState(0); // For animation

  useEffect(() => {
    if (freelancerId) {
      fetchWalletBalance(freelancerId);
    }
  }, [freelancerId]);

  // Animate balance from 0 to actual balance
  useEffect(() => {
    if (walletBalance && walletBalance.balance !== undefined) {
      let start = 0;
      const end = walletBalance.balance;
      const duration = 0.5; // 1 sec animation
      const stepTime = Math.abs(Math.floor(duration / end));

      const timer = setInterval(() => {
        start += 100;
        setAnimatedBalance(start);
        if (start >= end) clearInterval(timer);
      }, stepTime);

      return () => clearInterval(timer);
    }
  }, [walletBalance]);

  return (
    <div className="wallet-container">
      <h2>Wallet</h2>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">{error}</p>}
      {walletBalance && (
        <div className="wallet-details">
          <FaWallet className="wallet-icon" />
          <p>
            <strong>Freelancer ID:</strong> {walletBalance.freelancerId}
          </p>
          <p className="balance-amount">₹{animatedBalance}</p>
        </div>
      )}
    </div>
  );
};

export default WalletPage;
