// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .project-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 20px 15px; 
   
    
  } */

.project-form {
  display: flex;
  flex-direction: column;
  justify-content: start;
   /* align-items: center; */

  margin: 0 auto; /* Center the form horizontally */

  width: 100%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background-color: white;

  padding: 40px;
}

.project-form .form-group {
  padding: 20px 0px;
}

.project-form label {
  font-weight: bold;
  color: #333; /* Dark text for labels */
}

.project-form input,
.project-form select,
.project-form textarea {
  width: 100%;
  padding: 10px;

  border-radius: 4px;
  font-size: 16px;
}

.project-form button {
  background-color: #4caf50; /* Green background */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.project-form button:hover {
  background-color: #45a049; /* Darker green on hover */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/ProjectForm/ProjectForm.css"],"names":[],"mappings":"AAAA;;;;;;;;;KASK;;AAEL;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;GACrB,yBAAyB;;EAE1B,cAAc,EAAE,iCAAiC;;EAEjD,WAAW;EACX,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;EACnB,yCAAyC;EACzC,uBAAuB;;EAEvB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,WAAW,EAAE,yBAAyB;AACxC;;AAEA;;;EAGE,WAAW;EACX,aAAa;;EAEb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD","sourcesContent":["/* .project-form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1.5rem;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 20px 15px; \r\n   \r\n    \r\n  } */\r\n\r\n.project-form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: start;\r\n   /* align-items: center; */\r\n\r\n  margin: 0 auto; /* Center the form horizontally */\r\n\r\n  width: 100%;\r\n  max-width: 800px;\r\n  background: rgba(255, 255, 255, 0.8);\r\n  border-radius: 16px;\r\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\r\n  background-color: white;\r\n\r\n  padding: 40px;\r\n}\r\n\r\n.project-form .form-group {\r\n  padding: 20px 0px;\r\n}\r\n\r\n.project-form label {\r\n  font-weight: bold;\r\n  color: #333; /* Dark text for labels */\r\n}\r\n\r\n.project-form input,\r\n.project-form select,\r\n.project-form textarea {\r\n  width: 100%;\r\n  padding: 10px;\r\n\r\n  border-radius: 4px;\r\n  font-size: 16px;\r\n}\r\n\r\n.project-form button {\r\n  background-color: #4caf50; /* Green background */\r\n  color: #fff;\r\n  padding: 10px 20px;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n}\r\n\r\n.project-form button:hover {\r\n  background-color: #45a049; /* Darker green on hover */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
