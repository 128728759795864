// import React, { useEffect, useState } from "react";
// import { MdNotificationsActive } from "react-icons/md";
// import axios from "axios";

// const NotificationCount = () => {
//   const [notificationCount, setNotificationCount] = useState(0);
//   const [projects, setProjects] = useState([]);

//   // Fetch projects periodically (every 10 seconds)
//   useEffect(() => {
//     const fetchProjects = async () => {
//       try {
//         const response = await axios.get("http://localhost:5000/api/projects");
//         const newProjects = response.data;

//         // Update notification count based on new projects
//         if (newProjects.length > projects.length) {
//           setNotificationCount(newProjects.length - projects.length);
//         }

//         setProjects(newProjects);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     // Fetch every 10 seconds
//     const interval = setInterval(fetchProjects, 10000);

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [projects]); // Dependency on projects

//   return (
//     <div>
//       <header>
//         <ul className="navbar-links">
//           {/* Notification Icon */}
//           <li style={{ position: "relative" }}>
//             <MdNotificationsActive
//               style={{ fontSize: "40px", cursor: "pointer", color: "black" }}
//             />
//             {/* Notification Badge */}
//             {notificationCount > 0 && (
//               <span style={styles.notificationBadge}>{notificationCount}</span>
//             )}
//           </li>
//         </ul>
//       </header>
//     </div>
//   );
// };

// //Notification Badge Styling
// const styles = {
//   notificationBadge: {
//     position: "absolute",
//     top: "-5px",
//     right: "-5px",
//     backgroundColor: "red",
//     color: "white",
//     fontSize: "14px",
//     fontWeight: "bold",
//     borderRadius: "50%",
//     padding: "4px 8px",
//   },
// };

// export default NotificationCount;


import React, { useEffect, useState } from "react";
import { MdNotificationsActive } from "react-icons/md";
import axios from "axios";

const NotificationCount = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [projects, setProjects] = useState([]);
  const [newProjects, setNewProjects] = useState([]); // Store new projects
  const [showNotifications, setShowNotifications] = useState(false); // Toggle notification dropdown

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/projects");
        const fetchedProjects = response.data;
console.log("fetched projects",fetchedProjects);
        if (projects.length > 0) {
          // Find newly added projects (not in the existing list)
          const newEntries = fetchedProjects.filter(
            (project) => !projects.some((p) => p.id === project.id)
          );

          if (newEntries.length > 0) {
            setNewProjects((prev) => [...prev, ...newEntries]); // Append new projects
            setNotificationCount((prev) => prev + newEntries.length); // Increment count
          }
        }

        setProjects(fetchedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    const interval = setInterval(fetchProjects, 10000);
    return () => clearInterval(interval);
  }, [projects]);

  // Toggle notifications dropdown
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    setNotificationCount(0); // Reset count on view
    setNewProjects([]); // Clear new project list after viewing
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <header>
        <ul className="navbar-links">
          <li style={{ position: "relative" }}>
            {/* Notification Icon */}
            <MdNotificationsActive
              style={{ fontSize: "40px", cursor: "pointer", color: "black" }}
              onClick={toggleNotifications}
            />

            {/* Notification Badge */}
            {notificationCount > 0 && (
              <span style={styles.notificationBadge}>{notificationCount}</span>
            )}

            {/* Notification Dropdown */}
            {showNotifications && (
              <div style={styles.notificationDropdown}>
                <h4 style={styles.notificationTitle}>New Notifications</h4>
                {newProjects.length > 0 ? (
                  newProjects.map((project) => (
                    <div key={project.id} style={styles.notificationItem}>
                      {project.name}
                    </div>
                  ))
                ) : (
                  <div style={styles.notificationItem}>No new notifications</div>
                )}
              </div>
            )}
          </li>
        </ul>
      </header>
    </div>
  );
};

// Styles
const styles = {
  notificationBadge: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    backgroundColor: "red",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "50%",
    padding: "4px 8px",
  },
  notificationDropdown: {
    position: "absolute",
    top: "50px",
    right: "0",
    width: "250px",
    backgroundColor: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "10px",
    zIndex: 1000,
  },
  notificationTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
    borderBottom: "1px solid #ddd",
    paddingBottom: "5px",
  },
  notificationItem: {
    padding: "8px",
    borderBottom: "1px solid #f1f1f1",
    cursor: "pointer",
  },
};

export default NotificationCount;
