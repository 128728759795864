import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { MdLogout } from 'react-icons/md';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate(); // React Router navigation

  const handleLogout = async () => {
    // const token =localStorage.getItem("token");
    // console.log("Token retrieved from local storage"+token);
    // if (!token) {
    //     alert("No token found, unable to log out!");
    //     return;
    //  }
    try {
      // const validToken=await logoutUser(token);
      // console.log("Token retrieved from local storage"+validToken);
      logout();
      navigate("/signin");
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Logout failed. Please try again");
    }
  };

  return (
    <button
      onClick={handleLogout}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        background: '#f44336',
        color: '#fff',
        border: 'none',
        padding: '10px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      <MdLogout style={{
          fontSize: '20px',
        }}/>
    </button>
  );
};

export default Logout;
