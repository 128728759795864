// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  position: fixed; /* Fixes navbar at the top */
  top: 0;
  /* left: 0; */
  width: 98%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #92c7cf; /* Change as needed */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-logo img {
  max-width: 120px; /* Adjust size as needed */
  height: auto;
}

.navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.navbar-links li {
  display: flex;
  align-items: center;
}

.navbar-links li a,
.navbar-links li svg {
  font-size: 40px;
  cursor: pointer;
  text-decoration: none;
  color: #333; /* Adjust color as needed */
}

.navbar-links li a:hover,
.navbar-links li svg:hover {
  color: #007bff; /* Hover color */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Freelancer/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,4BAA4B;EAC7C,MAAM;EACN,aAAa;EACb,UAAU;EACV,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB,EAAE,qBAAqB;EAChD,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB,EAAE,0BAA0B;EAC5C,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;;EAEE,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,WAAW,EAAE,2BAA2B;AAC1C;;AAEA;;EAEE,cAAc,EAAE,gBAAgB;AAClC","sourcesContent":[".navbar {\r\n  position: fixed; /* Fixes navbar at the top */\r\n  top: 0;\r\n  /* left: 0; */\r\n  width: 98%;\r\n  z-index: 1000;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  background-color: #92c7cf; /* Change as needed */\r\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.navbar-logo img {\r\n  max-width: 120px; /* Adjust size as needed */\r\n  height: auto;\r\n}\r\n\r\n.navbar-links {\r\n  display: flex;\r\n  align-items: center;\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  gap: 20px;\r\n}\r\n\r\n.navbar-links li {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.navbar-links li a,\r\n.navbar-links li svg {\r\n  font-size: 40px;\r\n  cursor: pointer;\r\n  text-decoration: none;\r\n  color: #333; /* Adjust color as needed */\r\n}\r\n\r\n.navbar-links li a:hover,\r\n.navbar-links li svg:hover {\r\n  color: #007bff; /* Hover color */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
