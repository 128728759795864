import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { oragnizationRegister } from "../../../utils/constants";
// import "./OrganizationPageStyles.css"; // Import the new CSS file
import "./OragnizationPageStyles.css";

const OrganizationPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = {
        name: data.name,
        email: data.email,
        contactNo: data.phone,
        address: data.address,
        userId: userId,
      };
      await oragnizationRegister(formData);
      alert("Registration successful");
      navigate("/signin");
    } catch (error) {
      alert(error.response?.data?.message || "Register failed");
    }
  };

  return (
    <div className="form-container">
      <FormProvider {...methods}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="form-title">Organization Registration</h2>
          
          {/* Name Field */}
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              {...methods.register("name", {
                required: "Name is required",
                minLength: { value: 3, message: "At least 3 characters required" },
              })}
              className="input-field"
            />
            {errors.name && <p className="error-text">{errors.name.message}</p>}
          </div>
          
          {/* Email Field */}
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              {...methods.register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email format",
                },
              })}
              className="input-field"
            />
            {errors.email && <p className="error-text">{errors.email.message}</p>}
          </div>
          
          {/* Phone Field */}
          <div className="input-group">
            <label>Phone</label>
            <input
              type="tel"
              {...methods.register("phone", {
                required: "Phone number is required",
                pattern: { value: /^[0-9]{10}$/, message: "Must be 10 digits" },
              })}
              className="input-field"
            />
            {errors.phone && <p className="error-text">{errors.phone.message}</p>}
          </div>
          
          {/* Address Field */}
          <div className="input-group">
            <label>Address</label>
            <textarea
              {...methods.register("address", { required: "Address is required" })}
              className="input-field"
            />
            {errors.address && <p className="error-text">{errors.address.message}</p>}
          </div>
          
          {/* Submit Button */}
          <div className="button-group">
            <button type="submit" className="signup-btn">Register</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OrganizationPage;
