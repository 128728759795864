import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate=useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent page reload

    try {
      // Clear old messages
      setMessage('');
      setError('');

      // Call backend API
      const response = await axios.post('http://72.167.134.86:5002/api/auth/forgot-password', {
        username,
      });

      // Success message
      setMessage(response.data.message);
      navigate("/verify-otp")
    } catch (err) {
      console.error(err);

      // Handle 404 and 500 errors
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <div style={styles.container}>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          placeholder="Enter your username (email)"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Send OTP</button>
      </form>

      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

// Simple inline styles (or you can use Tailwind, MUI, etc.)
const styles = {
    container: {
        maxWidth: '400px',
        margin: '50px auto',
        textAlign: 'center',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        background: `linear-gradient(
            110deg,
            rgba(38, 159, 191, 1) 0%,
            rgba(239, 240, 4, 1) 52%
          )`,
        color: '#333',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
      },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
};

export default ForgotPassword;
