import React, { useEffect, useState } from "react";
import { getOrganizationDetails } from "../../utils/constants";
import '../Organization/OrganizationStyles.css'
import Navbar from "../Home/Freelancer/Navbar/Navbar";
import { Link } from "react-router-dom";

const OrganizationInfo = () => {
  const [showDetails, setShowDetails] = useState([]);
  const organizationId = localStorage.getItem("organizationId");
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (organizationId) {
          const data = await getOrganizationDetails(organizationId);
          // console.log("getting data from projects ||", data);
          setShowDetails(data); // Save the projects in state
          console.log(showDetails);
        } else {
          console.error("Organization ID not found");
        }
      } catch (error) {
        console.error("Error fetching OrganizationDetails:", error);
      }
    };

    fetchProjects();
  }, [organizationId,showDetails]);
  return (
<>
   <Navbar/>

    <div
  style={{
    display: "flex", // Use flexbox for centering
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    minHeight: "100vh", // Make it take the full height of the viewport
    
    backgroundColor:" #92C7CF", // Light background for the page
  }}
>
  
  {showDetails.map((m) => (
    <div
      key={m.id} // Always include a key for elements in a list
      style={{
        
        borderRadius: "10px", // Rounded corners
        boxShadow: "0 4px 8px rgba(17, 16, 16, 0.1)", // Subtle shadow for depth
        padding: "100px", // Space inside the card
        maxWidth: "800px", // Limit the card's width
        width: "100%", // Ensure it adapts to smaller screens
        margin: "20px", // Spacing between cards
        textAlign: "center", // Center the text content
        fontFamily: "Poppins-bold, sans-serif", // Clean font
        color: "#333", // Text color
      }}
    >
      <h1><b>Organization Details</b></h1>
      <h1
        style={{
          fontSize: "1.5rem", // Font size for the title
          color: "#0073e6", // Highlighted color for the name
          marginBottom: "10px", // Space below the name
        }}
      >
       <strong style={{color: "#441752"}}>Organization Name:</strong> {m.name}
      </h1>
      
      <p>
        <strong>Email:</strong> {m.email}
      </p>
      <p>
        <strong>Contact Number:</strong> {m.contactNo}
      </p>
      <p>
        <strong>Address:</strong> {m.address}
      </p>
      <Link to={"/home"}><button className="return-btn">Go to Home</button></Link>
    </div>
  ))}
</div>

</>
  );
};

export default OrganizationInfo;
