import React, { useEffect, useState, useRef } from "react";
import socket from "../../Socket/socket";
import "./Chat.css"; // Import the external CSS file

const Chat = () => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const currentUserId = "123"; // Logged-in user ID
  const chatContainerRef = useRef(null);

  useEffect(() => {
    socket.emit("register", currentUserId);
    socket.on("chat", (message) => {
      setChatMessages((prev) => [...prev, message]);
    });

    return () => {
      socket.off("chat");
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const sendMessage = () => {
    if (!message.trim()) return;

    const newMessage = {
      senderId: currentUserId,
      receiverId: "456",
      messageText: message,
    };

    socket.emit("chat", newMessage);
    setChatMessages((prev) => [...prev, newMessage]);
    setMessage("");
  };

  return (
    <div className="chat-container">
   

      {/* Chat Messages Container */}
      <div ref={chatContainerRef} className="chat-box">
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            className={`message-bubble ${
              msg.senderId === currentUserId ? "sent" : "received"
            }`}
          >
            {msg.messageText}
          </div>
        ))}
      </div>

      {/* Message Input */}
      <div className="input-container">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          className="chat-input"
        />
        <button onClick={sendMessage} className="send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;