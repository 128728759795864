import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {

//   const location = useLocation();

//   const token = localStorage.getItem('token');
// console.log(token,location.pathname);

//   if (token && (location.pathname === "/signin" || location.pathname === "/signup")) {
//     return <Navigate to="/home" replace />;
//   }

  return isAuthenticated ? children : <Navigate to="/signin" replace />;
};

export default ProtectedRoute;
