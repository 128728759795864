// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the PayPal button */
#paypal-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Full-screen height */
    
  }
  
  /* Add a subtle background */
  .paypal-container {
    
      background-color: #92C7CF;
     
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 500px;
    margin: auto;
    margin-top: 200px;
  }
  
  /* Add heading styles */
  .paypal-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Loader style */
  .loading-text {
    font-size: 18px;
    color: #0070ba;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Payment/Paypal/Paypal.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAE,uBAAuB;;EAEvC;;EAEA,4BAA4B;EAC5B;;MAEI,yBAAyB;;IAE3B,aAAa;IACb,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;;EAEA,uBAAuB;EACvB;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA,iBAAiB;EACjB;IACE,eAAe;IACf,cAAc;IACd,iBAAiB;EACnB","sourcesContent":["/* Center the PayPal button */\r\n#paypal-button-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: auto; /* Full-screen height */\r\n    \r\n  }\r\n  \r\n  /* Add a subtle background */\r\n  .paypal-container {\r\n    \r\n      background-color: #92C7CF;\r\n     \r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n    width: 500px;\r\n    margin: auto;\r\n    margin-top: 200px;\r\n  }\r\n  \r\n  /* Add heading styles */\r\n  .paypal-heading {\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  /* Loader style */\r\n  .loading-text {\r\n    font-size: 18px;\r\n    color: #0070ba;\r\n    font-weight: bold;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
