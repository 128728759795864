// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button specific styles */
.submit-button button {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  .submit-button button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .submit-button button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Form/Fields/SubmitButton/SubmitButton.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,6CAA6C;EAC/C;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Button specific styles */\r\n.submit-button button {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    border: none;\r\n    cursor: pointer;\r\n    font-size: 1rem;\r\n    padding: 0.75rem 1.5rem;\r\n    transition: background-color 0.2s ease-in-out;\r\n  }\r\n  \r\n  .submit-button button:disabled {\r\n    background-color: #ccc;\r\n    cursor: not-allowed;\r\n  }\r\n  \r\n  .submit-button button:hover:not(:disabled) {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
