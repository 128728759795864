import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

const CheckBoxField = ({ name, label }) => {
    const { register } = useFormContext();
  return (
    <div className="form-group">
     <FormControl component="fieldset" >
      <FormControlLabel
        control={<Checkbox {...register(name)} />}
        label={label}
      />
      {/* {error && <FormHelperText>{error.message}</FormHelperText>} */}
    </FormControl>
  </div>
    
  )
}

export default CheckBoxField