import React from "react";
import SelectDropdown from "../Fields/SelectDropdown/SelectDropdown";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../Fields/InputField/InputField";
import EditorField from "../Fields/EditorField/EditorField";
import CheckBoxField from "../Fields/CheckBoxField/CheckBoxField";
import SubmitButton from "../Fields/SubmitButton/SubmitButton";
import "./ProjectForm.css";
import { Box } from "@mui/material";
import axios from "axios";
import RadioButtonGroup from "../Fields/RadioButton/RadioButtonGroup";
import { useNavigate } from "react-router-dom";
import { projectForm_API_URL } from "../../../utils/constants";

const ProjectForm = () => {
  const methods = useForm();
const navigate=useNavigate();
  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const termsAccepted = watch("terms");
  
  const currencyOptions = [
    { label: "India - INR", value: "INR" },
    { label: "United States - USD", value: "USD" },
    { label: "United Kingdom - GBP", value: "GBP" },
    { label: "European Union - EUR", value: "EUR" },
    { label: "Japan - JPY", value: "JPY" },
    { label: "China - CNY", value: "CNY" },
    { label: "Canada - CAD", value: "CAD" },
    { label: "Australia - AUD", value: "AUD" },
    { label: "Russia - RUB", value: "RUB" },
    { label: "Brazil - BRL", value: "BRL" },
    { label: "South Africa - ZAR", value: "ZAR" },
    { label: "Switzerland - CHF", value: "CHF" },
    { label: "Saudi Arabia - SAR", value: "SAR" },
    { label: "United Arab Emirates - AED", value: "AED" },
    { label: "Singapore - SGD", value: "SGD" },
    { label: "Malaysia - MYR", value: "MYR" },
    { label: "Indonesia - IDR", value: "IDR" },
    { label: "South Korea - KRW", value: "KRW" },
    { label: "Mexico - MXN", value: "MXN" },
    { label: "Turkey - TRY", value: "TRY" },
    { label: "Thailand - THB", value: "THB" },
    { label: "Vietnam - VND", value: "VND" },
    { label: "Egypt - EGP", value: "EGP" },
    { label: "Argentina - ARS", value: "ARS" },
    { label: "New Zealand - NZD", value: "NZD" },
  ];

  // const showLocation = watch("selectSpecificLocation", "no") === "yes";
  const organizationId = localStorage.getItem("organizationId");
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const formattedData = {
        projectName: data.Project,
        projectDescription: data.editorial,
        projectCategory: data.Category,
        projectType: data.projectType,
        currencyType: data.currency,
        amount: data.amount,
        location: data.location,
        visibility: data.radioOption,
        projectSkill: data.projectSkill,
        organizationId:organizationId
        // terms: data.terms,
        // selectSpecificLocation:data.selectSpecificLocation,
      };
      console.log(formattedData);
      const response = await axios.post(
        projectForm_API_URL,
        formattedData
      );
      console.log("Response from server:", response.data);
      navigate("/home");
      // Reset form fields
      reset();
    } catch (error) {
      console.error(
        "Error submitting project:",
        error.response?.data || error.message
      );
    }
  };

  return (
   <div className="container">
      
      <FormProvider {...methods}>
        <form  onSubmit={handleSubmit(onSubmit)}>
          <div className="project-form">
            <h1 style={{color:"black"}}><i>Project form</i></h1>
          <InputField className="form-group"
            label="Project Title"
            name="Project"
            register={methods.register("Project", {
              required: "Project title is required",
              minLength: {
                value: 3,
                message: "Title must be at least 3 characters",
              },
            })}
            error={errors.Project}
          />

          <EditorField name="editorial" className="form-group"/>

          <SelectDropdown className="form-group"
            options={[
              { label: "Technology & Software", value: "technology" },
              { label: "Healthcare & Pharmaceuticals", value: "healthcare" },
              { label: "Finance & Banking", value: "finance" },
              { label: "Education & E-Learning", value: "education" },
              { label: "Retail & E-Commerce", value: "retail" },
            ]}
            register={methods.register("Category", {
              required: "Please select the Category",
            })}
            placeholder={"Select the Category"}
            error={errors.Category}
          />

          <InputField className="form-group"
            label="Project Skill"
            name="projectSkill"
            register={methods.register("projectSkill", {
              required: "Skill is required",
            })}
            error={errors.Project}
          />

          <RadioButtonGroup className="form-group"
            label="Project Type"
            name="projectType"
            options={[
              { label: "Hourly", value: "hourly" },
              { label: "Fixed", value: "fixed" },
            ]}
            error={errors.projectType}
          />

          <Box
            display="flex"
            justifyContent={"space-around"}
            alignItems="center"
            sx={{ width: "100%" }}
          >

           
             <SelectDropdown className="form-group"
              name="currencyType"
              options={currencyOptions}
              register={methods.register("currency", {
                required: "Currency is required",
              })}
              error={errors.currency}
              placeholder="Select a currency"
            /> 
            <InputField className="form-group"
              register={methods.register("amount", {
                required: "Amount is required",
                valueAsNumber: true,
              })}
              error={errors.amount}
              type="number"
            />
          </Box> 

          <RadioButtonGroup className="form-group"
            label="Location"
            name="location"
            options={[{ label: "Remote", value: "remote" }]}
          />

          <RadioButtonGroup className="form-group"
            control={control}
            name="radioOption"
            error={errors.radioOption}
            label="Project Visibility"
            options={[
              { label: "Public", value: "public" },
              { label: "Private", value: "private" },
            ]}
          />

          <CheckBoxField className="form-group"
            name="terms"
            label="I agree to the VighnaAoress OPC Pvt Ltd Terms and Conditions"
          />
          <SubmitButton disabled={!termsAccepted} />
          </div>
        </form>
      </FormProvider>
      </div>
  );
};

export default ProjectForm;
