import React from "react";

import OrganizationDashboard from "../Organization/OrganizationDashboard";

const Home = () => {
  return (
    <div>
      <OrganizationDashboard />
    </div>
  );
};

export default Home;
