import React from 'react'
import "./UserProfile.css"
import Navbar from "../Navbar/Navbar"
import { Link } from 'react-router-dom';

const UserProfile = () => {
   const username= localStorage.getItem("username");
   const role=localStorage.getItem("userRole");
  return (
    <div>
      <Navbar/>
    <div className="profile-container">
    <div className="profile-card">
            <h2>User Profile</h2>
            <p><strong>Username:</strong> {username}</p>
            <p><strong>Role:</strong> {role}</p>
            <Link to={"/freelancer"}><button className="return-btn">Go to Home</button></Link>
        </div>
       
        </div>
        </div>
  )
}

export default UserProfile;