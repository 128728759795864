import React from 'react'
import './SubmitButton.css'
import { Button } from '@mui/material'
const SubmitButton = ({ disabled,label }) => {
   
  return (
   
    <Button variant="contained"   style={{
      padding: "10px 20px",
      backgroundColor: "#e50914", // Netflix red
      border: "none",
      borderRadius: "5px",
      color: "white",
      fontSize: "1rem",
      cursor: "pointer",
    }} type="submit"  disabled={disabled}>
     {label || "Submit"}
   </Button>
   
  )
}

export default SubmitButton