import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";


// Create Auth Context
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Check for user token on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("Auth context load");
        const isTokenValid = decodedToken.exp * 1000 > Date.now();
        if (isTokenValid) {
          const role = localStorage.getItem("userRole");
          setUser({ token, role });
          
        } else {
          logout();
        }
      } catch (error) {
        console.error("Invalid token:", error);
        
      }
    }
  }, []);

  const login = (token,role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userRole',role);
    setUser({ token,role });
  };

  const logout = () => {
    const keysToRemove = ['token', 'userRole', 'organizationId', 'username', 'freelancerId'];

    keysToRemove.forEach((key) => localStorage.removeItem(key));
    
    setUser(null);

  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
