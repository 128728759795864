import axios from "axios";
import React, { useEffect, useState } from "react";
import "./ProjectList.css"
import useWallet from "../../../../utils/Hooks/useWallet";
import { getProjectAcceptedList_API_URL, projectAccept_API_URL } from "../../../../utils/constants";



const ProjectList = ({ projectId, projectName, projectDescription, projectSkill,projectAmount,currencyType }) => {
  const freelancerId = localStorage.getItem("freelancerId"); // Get freelancerId from localStorage

  const [accepted, setAccepted] = useState(false); // Track if this freelancer accepted
  const [alreadyAccepted, setAlreadyAccepted] = useState(false); // Track if project is taken

    // Use wallet custom hook
    const { updateWalletBalance } = useWallet();

  // Fetch project assignment status
  useEffect(() => {
    const fetchProjectStatus = async () => {
      try {
        const response = await axios.get(getProjectAcceptedList_API_URL+projectId);
        if (response.data.freelancerId) {
          setAlreadyAccepted(true); // If assigned, disable button
        }
      } catch (error) {
        console.error("Error fetching project status:", error);
      }
    };

    fetchProjectStatus();
  }, [projectId]);

  const handleAccept = async () => {
    try {
      const response = await axios.post( projectAccept_API_URL, {
        freelancerId,
        projectId,
      });

      if (response.status === 200) {
        setAccepted(true); // Disable button after success
        setAlreadyAccepted(true);
console.log("call custom hook");
         // Call custom hook function to update wallet balance
       await updateWalletBalance(freelancerId,projectId, projectAmount);
         console.log("call custom hook working ||");
      }
    } catch (error) {
      console.error("Error accepting project:", error);
    }
  };

  return (
    <div className="project-container">
      <h2 > Project Name: <b>{projectName}</b></h2>
      <p style={{ margin: "0 0 10px", color: "blue" }}>
        <strong>Description:</strong> {projectDescription}
      </p>
      <p style={{ color: "#35374B" }}>
        <strong>Skills:</strong>{" "}
        {Array.isArray(projectSkill) ? projectSkill.join(", ") : projectSkill || "No skills provided"}
      </p>
      <p>{currencyType}:{projectAmount}</p>
      <button
        onClick={handleAccept}
        disabled={accepted || alreadyAccepted}
        style={{
          padding: "5px 15px",
          backgroundColor: alreadyAccepted ? "gray" : accepted ? "red" : "green",
          color: "white",
          cursor: accepted || alreadyAccepted ? "not-allowed" : "pointer",
        }}
      >
        {alreadyAccepted ? "Already Taken" : accepted ? "Accepted" : "Accept"}
      </button>
    </div>
  );
};

export default ProjectList;
