import React, { useState } from "react";
import "./Chatbot.css";
import Chat from "../../../src/Pages/Chat/Chat"



const ChatbotButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Floating Chat Button */}
      <button className="floating-button" onClick={() => setOpen(!open)}>
        Contact Us
      </button>

      {/* Chatbot Window */}
      <div className={`chatbot-container ${open ? "active" : ""}`}>
        <div className="chatbot-header">
          Chatbox
          <button className="close-button" onClick={() => setOpen(false)}>✖</button>
        </div>
        
        {/* Conditionally render ChatComponent */}
        <div className="chatbot-content">
          {open ? <Chat/> : null}
        </div>
      </div>
    </>
  );
};

export default ChatbotButton;
