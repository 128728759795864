import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUserLarge } from "react-icons/fa6";
import "./userDropdown.css";

const UserDropdown = ({ username }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="user-dropdown" ref={dropdownRef}>
      <FaUserLarge
        style={{ fontSize: "40px", cursor: "pointer" }}
        onClick={toggleDropdown}
      />

      {open && (
        <div className="dropdown-menu">
          <div className="dropdown-item username">👤 {username}</div>
          <Link to="/userprofile" className="dropdown-item">
           <p style={{fontSize:"12px"}}>View Profile</p> 
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
