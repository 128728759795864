import { useEffect,useState } from "react";
import "./Paypal.css";
const PayPalSubscription = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (!document.querySelector("script[src*='paypal.com/sdk/js']")) {
          const script = document.createElement("script");
          script.src =
            "https://www.paypal.com/sdk/js?client-id=AVd4x6JcFqNygjiizkvK3uEPqyuaRHNAjp6Mnvmws90Hzb1cwYJFaDYQhVyH-e-asJAH77_0IZN73pc8&vault=true&intent=subscription" ;
          script.async = true;
          script.onload = () => {
            setIsLoading(false); // Hide loader when PayPal is ready
            if (window.paypal) {
              window.paypal
                .Buttons({
                  style: { shape: "rect", color: "gold", layout: "vertical", label: "subscribe" },
                  createSubscription: (data, actions) =>
                    actions.subscription.create({ plan_id: "P-2NS07829TE7220038M7J5CFA" }),
                  onApprove: (data) => alert(`Subscription Successful! ID: ${data.subscriptionID}`),
                })
                .render("#paypal-button-container");
            }
          };
          document.body.appendChild(script);
        }
      }, []);
      

  return (
    <div className="paypal-container">
    <h2 className="paypal-heading">Subscribe with PayPal</h2>
    {isLoading ? <p className="loading-text">Loading PayPal...</p> : null}
    <div id="paypal-button-container"></div>
  </div>
  );
};

export default PayPalSubscription;

// import { useEffect, useState } from "react";

// const PayPalSubscription = () => {
//   const [isSdkReady, setSdkReady] = useState(false);

//   useEffect(() => {
//     // Check if PayPal SDK is already loaded
//     if (window.paypal) {
//       setSdkReady(true);
//       renderPayPalButton();
//       return;
//     }

//     // Create PayPal script dynamically
//     const script = document.createElement("script");
//     script.src =
//       "https://www.paypal.com/sdk/js?client-id=AVd4x6JcFqNygjiizkvK3uEPqyuaRHNAjp6Mnvmws90Hzb1cwYJFaDYQhVyH-e-asJAH77_0IZN73pc8&vault=true&intent=subscription" ;
//     script.async = true;
//     script.onload = () => {
//       setSdkReady(true);
//       renderPayPalButton();
//     };

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   // Function to render PayPal button
//   const renderPayPalButton = () => {
//     if (window.paypal) {
//       window.paypal
//         .Buttons({
//           style: { shape: "rect", color: "gold", layout: "vertical", label: "subscribe" },
//           createSubscription: (data, actions) =>
//             actions.subscription.create({ plan_id: "P-2NS07829TE7220038M7J5CFA" }),
//           onApprove: (data) => alert(`Subscription Successful! ID: ${data.subscriptionID}`),
//         })
//         .render("#paypal-button-container");
//     }
//   };

//   return (
//     <div>
//       {isSdkReady ? (
//         <div id="paypal-button-container"></div>
//       ) : (
//         <p>Loading PayPal...</p>
//       )}
//     </div>
//   );
// };

// export default PayPalSubscription;



