import { useState } from "react";
import axios from "axios";
import { wallet_API_URL } from "../constants";
const useWallet = () => {
  const [walletBalance, setWalletBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch Wallet Balance
  const fetchWalletBalance = async (freelancerId) => {
    setLoading(true);
    try {
      const response = await axios.get(wallet_API_URL + freelancerId);
      setWalletBalance(response.data);
      console.log("get wallet details", walletBalance);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching wallet details");
    } finally {
      setLoading(false);
    }
  };

  // Update Wallet Balance
  const updateWalletBalance = async (freelancerId, projectId, amount) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://72.167.134.86:5002/api/walletUpdate",
        {
          freelancerId,
          projectId,
          amount,
        }
      );

      if (response.status === 200) {
        setWalletBalance(response.data.balance); // Update state with new balance
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return {
    walletBalance,
    loading,
    error,
    fetchWalletBalance,
    updateWalletBalance,
  };
};

export default useWallet;
