import React, { useReducer } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Initial state
const initialState = {
  username: "",
  otp: "",
  newPassword: "",
  confirmPassword: "",
  message: "",
  error: "",
  loading: false,
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "SET_LOADING":
      return { ...state, loading: action.value };
    case "SET_MESSAGE":
      return { ...state, message: action.message, error: "" };
    case "SET_ERROR":
      return { ...state, error: action.error, message: "" };
    case "RESET_FORM":
      return initialState;
    default:
      return state;
  }
};

const ResetPassword = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const {
    username,
    otp,
    newPassword,
    confirmPassword,
    message,
    error,
    loading,
  } = state;

  // Handle input changes
  const handleInputChange = (e) => {
    dispatch({
      type: "SET_FIELD",
      field: e.target.name,
      value: e.target.value,
    });
  };

  // Handle form submission
  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      dispatch({
        type: "SET_ERROR",
        error: "Passwords do not match",
      });
      return;
    }

    dispatch({ type: "SET_LOADING", value: true });
    dispatch({ type: "SET_ERROR", error: "" });

    try {
      const response = await axios.post(
        "http://72.167.134.86:5002/api/auth/reset-password",
        {
          username,
          newPassword,
        }
      );

      dispatch({
        type: "SET_MESSAGE",
        message: response.data.message || "Password reset successful!",
      });
      alert("Password reset successful!");
      navigate("/signin");
      // Optional: Reset form or navigate to login
      // dispatch({ type: 'RESET_FORM' });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "SET_ERROR",
        error: err.response?.data?.message || "Something went wrong",
      });
    } finally {
      dispatch({ type: "SET_LOADING", value: false });
    }
  };

  return (
    <div style={styles.container}>
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPassword} style={styles.form}>
        <input
          type="text"
          name="username"
          placeholder="Enter your username/email"
          value={username}
          onChange={handleInputChange}
          style={styles.input}
          required
        />

        <input
          type="password"
          name="newPassword"
          placeholder="Enter new password"
          value={newPassword}
          onChange={handleInputChange}
          style={styles.input}
          required
        />

        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm new password"
          value={confirmPassword}
          onChange={handleInputChange}
          style={styles.input}
          required
        />

        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? "Resetting..." : "Reset Password"}
        </button>
      </form>

      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

const styles = {
    container: {
      maxWidth: '400px',
      margin: '50px auto',
      textAlign: 'center',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      background: `linear-gradient(
          110deg,
          rgba(38, 159, 191, 1) 0%,
          rgba(239, 240, 4, 1) 52%
        )`,
      color: '#333',
      boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
    },
    heading: {
      color: '#222',
      marginBottom: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    input: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    button: {
      padding: '10px',
      fontSize: '16px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
    },
  };

export default ResetPassword;
