import React from 'react';
import { TextField} from '@mui/material';

const InputField = ({ label, register, error,type}) => {
   
  return (
    <div className="form-group">
   <div style={{ width: '250px' }}>
   <TextField
      label={label}
      type={type || ""}
      variant="outlined"
      {...register}
      error={!!error}
      helperText={error ? error.message : ''}
   fullWidth   
    />
  </div>
  </div>
  )
}

export default InputField