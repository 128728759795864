import React, { useContext, useRef, useState } from "react";
import { login } from "../../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { jwtDecode } from "jwt-decode";
import "../Login/LoginStyles.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import useAutoLogin from "../../../utils/Hooks/useAutoLogin";

const Login = () => {
  const navigate = useNavigate();
  const { login: setAuthLogin } = useContext(AuthContext);

  // Refs for form fields
  const usernameRef = useRef();
  const passwordRef = useRef();

  // State for validation errors
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const username = usernameRef.current.value.trim();
    const password = passwordRef.current.value.trim();
    const newErrors = {};

    if (!username) {
      newErrors.Username = "Username is required";
    } else if (username.length < 3) {
      newErrors.Username = "Username must be at least 3 characters";
    }

    if (!password) {
      newErrors.Password = "Password is required";
    } else if (password.length < 3) {
      newErrors.Password = "Password must be at least 3 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      const formData = {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };

      const response = await login(formData);
      const { userId: freelancerId, username, token } = response.data;
      localStorage.setItem("username", username);
      localStorage.setItem("freelancerId", freelancerId);

      const decodedToken = jwtDecode(token);
      const { role, organizationId } = decodedToken.userId;

      setAuthLogin(token, role);

      if (role === "organization" && organizationId) {
        localStorage.setItem("organizationId", organizationId);
      }

      navigate(
        role === "freelancer" ? "/freelancer" :
        role === "organization" ? "/home" :
        role === "admin" ? "/selection" : "/"
      );

      alert("Signin successful");
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Signin failed";
      alert(errorMessage);
      console.error(error.response?.data || error);
    }
  };

  useAutoLogin(setAuthLogin);

  return (
    <div className="form-container" >
     
      <form className="form" onSubmit={onSubmit} >
        <h2 className="form-title">Login</h2>
        <div className="input-group">
          <label>Email</label>
          <input
            type="text"
            name="Username"
            className="input-field"
            ref={usernameRef}
          />
          {errors.Username && <p className="error-text">{errors.Username}</p>}
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            name="Password"
            className="input-field"
            ref={passwordRef}
          />
          {errors.Password && <p className="error-text">{errors.Password}</p>}
        </div>
        <div className="button-group">
          <button type="submit" className="login-btn">
            Login <FaArrowAltCircleRight className="icon" />
          </button>
          </div>
          {/* <Link to="/signup">
            <button className="Signup-btn">
              Signup <FaArrowAltCircleRight className="icon" />
            </button>
          </Link> */}
           <p className="signup-text">
    Are you new to this FreelanceApp? <Link to="/signup" className="signup-link">Sign up here</Link>
  </p>
         <Link to={"/forgot-password"}> <h5 className="forgot-password">Forgot Password?</h5></Link>
        
      </form>
      </div>
   
  );
};

export default Login;
