import React from 'react'
import "./PaymentPlans.css"

const PaymentPlans = () => {
  return (
    <div className="plan-container">
  <div className="plan-card">
    <h2>Economy Plan</h2>
    <ul>
      <li>5 tasks per month</li>
      <li>Website updates & bug fixes</li>
      <li>Branding & graphic design</li>
      <li>Basic digital marketing</li>
      <li>Support & maintenance</li>
      <li>No extra charges till subscription ends</li>
    </ul>
    <button>Get Started</button>
  </div>

  <div className="plan-card">
    <h2>Standard Plan</h2>
    <ul>
      <li>10 tasks per month</li>
      <li>Advanced website maintenance</li>
      <li>Logo, pamphlet & branding design</li>
      <li>SEO & social media strategy</li>
      <li>Priority support</li>
      <li>No extra charges till subscription ends</li>
    </ul>
    <button>Get Started</button>
  </div>

  <div className="plan-card">
    <h2>Premium Plan</h2>
    <ul>
      <li>20 tasks per month</li>
      <li>Full-stack web & app development</li>
      <li>AI automation & chatbot integration</li>
      <li>Advanced digital marketing & SEO</li>
      <li>Dedicated account manager</li>
      <li>No extra charges till subscription ends</li>
    </ul>
    <button>Get Started</button>
  </div>

  <div className="plan-card">
    <h2>Premium Plus Plan</h2>
    <ul>
      <li>Unlimited tasks per month</li>
      <li>Enterprise-level IT solutions</li>
      <li>Custom AI & automation tools</li>
      <li>Advanced data analytics & reporting</li>
      <li>24/7 dedicated support</li>
      <li>No extra charges till subscription ends</li>
    </ul>
    <button>Get Started</button>
  </div>

  <div className="plan-card" >
    <h2>Pay As You Go</h2>
    <ul>
      <li>Flexible task-based pricing</li>
      <li>Pay only for what you need</li>
      <li>Website & app fixes, AI tools, marketing</li>
      <li>Higher cost per task</li>
      <li>Premium support included</li>
    </ul>
    <button>Get Started</button>
  </div>
</div>

  )
}

export default PaymentPlans