import React from "react";
import ProjectForm from "../../Components/Form/ProjectForm/ProjectForm";

const Organization = () => {
  return (
    <div>
      <ProjectForm />
    </div>
  );
};

export default Organization;
