import axios from "axios";

 export const API_BASE_URL = "http://72.167.134.86:5002/api";


export const signup = async (data) => {
  return axios.post(`${API_BASE_URL}/auth/csignup`, data);
};

export const login = async (data) => {
  return axios.post(`${API_BASE_URL}/auth/csignin`, data);
};

export const logoutUser = async (token) => {
  return axios.post(
    `${API_BASE_URL}/auth/csignout`, // API endpoint for logout
    {}, // Empty request body
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    }
  );
};

export const oragnizationRegister = async (data) => {
  return axios.post(`${API_BASE_URL}/createOrganization`, data);
};

export const getProjects = async (organizationId) => {
  try {
    console.log("get projects from constants", organizationId);
    const response = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/projects`
    );
    console.log(response.data);
    return response.data; // Assuming the API returns data in the response
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error; // Propagate the error to handle it in the calling function
  }
};

export const getOrganizationDetails=async(organizationId)=>{
  try {
    console.log("get projects from constants", organizationId);
    const response = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/getOrganizationInfo`
    );
    console.log(response.data);
    return response.data; // Assuming the API returns data in the response
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error; // Propagate the error to handle it in the calling function
  }
}

export const wallet_API_URL=`${API_BASE_URL}/getWalletInfo/`;

export const projectForm_API_URL=`${API_BASE_URL}/projects`;

export const projectAccept_API_URL=`${API_BASE_URL}/projects/accept`;

export const getProjectAcceptedList_API_URL=`${API_BASE_URL}/projects/`;
