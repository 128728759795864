import React, { useReducer } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Step 1: Define initial state
const initialState = {
  username: '',
  otp: '',
  message: '',
  error: '',
  loading: false,
};

// Step 2: Define reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'SET_LOADING':
      return { ...state, loading: action.value };
    case 'SET_MESSAGE':
      return { ...state, message: action.message, error: '' };
    case 'SET_ERROR':
      return { ...state, error: action.error, message: '' };
    case 'RESET_FORM':
      return initialState;
    default:
      return state;
  }
};

const VerifyOtp = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { username, otp, message, error, loading } = state;
const navigate=useNavigate();
  const handleInputChange = (e) => {
    dispatch({
      type: 'SET_FIELD',
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    dispatch({ type: 'SET_LOADING', value: true });
    dispatch({ type: 'SET_ERROR', error: '' });

    try {
      const response = await axios.post('http://72.167.134.86:5002/api/auth/verify-otp', {
        username,
        otp,
      });

      dispatch({ type: 'SET_MESSAGE', message: response.data.message });
      navigate("/reset-password");
      // Optional: Reset form or navigate to reset-password page
      // dispatch({ type: 'RESET_FORM' });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'SET_ERROR',
        error: err.response?.data?.message || 'Something went wrong',
      });
      alert('Something went wrong');
    } finally {
      dispatch({ type: 'SET_LOADING', value: false });
    }
  };

  return (
    <div style={styles.container}>
      <h2>Verify OTP</h2>
      <form onSubmit={handleVerifyOtp} style={styles.form}>
        <input
          type="text"
          name="username"
          placeholder="Enter your username (email)"
          value={username}
          onChange={handleInputChange}
          style={styles.input}
          required
        />

        <input
          type="text"
          name="otp"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleInputChange}
          style={styles.input}
          required
        />

        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? 'Verifying...' : 'Verify OTP'}
        </button>
      </form>

      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

const styles = {
    container: {
        maxWidth: '400px',
        margin: '50px auto',
        textAlign: 'center',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        background: `linear-gradient(
            110deg,
            rgba(38, 159, 191, 1) 0%,
            rgba(239, 240, 4, 1) 52%
          )`,
        color: '#333',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
      },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
};

export default VerifyOtp;
