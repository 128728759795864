import React from "react";
import { useFormContext } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./EditorField.css";
const EditorField = ({ name }) => {
  const { setValue } = useFormContext();
  return (
    <div className="form-group">
      <label>Project Description</label>
      <CKEditor
        editor={ClassicEditor}
        onChange={(event, editor) => {
          let editorData = editor.getData();
          editorData = editorData
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, "")
            .replace(/<br>/g, "")
            .replace(/<\/br>/g, "");
            // Remove all <p> tags
          setValue(name, editorData);
        }}
      />
    </div>
  );
};

export default EditorField;
