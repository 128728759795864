// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    background-color: #92c7cf;
    margin: auto;
    padding: 20px;
   
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    text-align: center;
  }
  
  .form {
    max-width: 800px;
    display: flex;
    flex-direction: column;
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color:#F9F9F9;
  }
  .input-field:focus {
    border-color: #007bff;
    outline: none;
   
  }
  
  .error-text {
    color: red;
    font-size: 12px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
  }
  
  .signup-btn {
    background: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .signup-btn:hover {
    background: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Auth/OrganizationRegisterPage/OragnizationPageStyles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;;IAEb,8CAA8C;IAC9C,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wBAAwB;EAC1B;EACA;IACE,qBAAqB;IACrB,aAAa;;EAEf;;EAEA;IACE,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".form-container {\r\n    background-color: #92c7cf;\r\n    margin: auto;\r\n    padding: 20px;\r\n   \r\n    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */\r\n    border-radius: 8px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .form {\r\n    max-width: 800px;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .form-title {\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .input-group {\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  label {\r\n    font-weight: bold;\r\n    display: block;\r\n    margin-bottom: 5px;\r\n  }\r\n  \r\n  .input-field {\r\n    width: 100%;\r\n    padding: 12px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    background-color:#F9F9F9;\r\n  }\r\n  .input-field:focus {\r\n    border-color: #007bff;\r\n    outline: none;\r\n   \r\n  }\r\n  \r\n  .error-text {\r\n    color: red;\r\n    font-size: 12px;\r\n  }\r\n  \r\n  .button-group {\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .signup-btn {\r\n    background: #007bff;\r\n    color: white;\r\n    padding: 10px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    width: 100%;\r\n  }\r\n  \r\n  .signup-btn:hover {\r\n    background: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
