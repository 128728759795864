import React from 'react';
import {  Controller } from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
 FormHelperText,
} from "@mui/material";

const RadioButtonGroup = ({control,name,options,label,error}) => {
    // const { control, handleSubmit } = useForm();
    return (
   <>
    <FormLabel component="legend">{label}</FormLabel>
    <Controller
      name={name}
      control={control}
      defaultValue={options[0].value} // Set a default value if needed
      rules={{ required: `${label} is required` }}
      render={({ field, fieldState }) => (
        <>
          <RadioGroup {...field}>
           
            {options.map((option) => (
                 
              <FormControlLabel
              
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {/* Show validation error */}
          {fieldState.error && (
           <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </>
      )}
    />
   
    </>
  )
}

export default RadioButtonGroup