// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Poppins:wght@600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
    font-family: "DMsans";
    src: url("../../../../Assets/fonts/dmsans/DMSans-Italic-VariableFont_opsz\\,wght.ttf");
    font-weight: 500;
    font-style: normal;
}
.project-name {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #333;
} */

body {
    font-family: 'Roboto', sans-serif;
 /* For general text */
}


h1, h2, h3 {
    font-family: "Open Sans",serif; /* Stylish headings */
    font-weight: 800;
    font-style: oblique;
    color: #333;
}

.project-container{
   
    padding: 20px;
    border-radius: 20px;
    
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Freelancer/ProjectList/ProjectList.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;GAWG;;AAMH;IACI,iCAAiC;CACpC,qBAAqB;AACtB;;;AAGA;IACI,8BAA8B,EAAE,qBAAqB;IACrD,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,mBAAmB;;AAEvB","sourcesContent":["/* @font-face {\r\n    font-family: \"DMsans\";\r\n    src: url(\"../../../../Assets/fonts/dmsans/DMSans-Italic-VariableFont_opsz\\,wght.ttf\");\r\n    font-weight: 500;\r\n    font-style: normal;\r\n}\r\n.project-name {\r\n    font-family: \"DM Sans\", sans-serif;\r\n    font-size: 24px;\r\n    font-weight: 500;\r\n    color: #333;\r\n} */\r\n\r\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Poppins:wght@600&display=swap');\r\n\r\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');\r\n\r\nbody {\r\n    font-family: 'Roboto', sans-serif;\r\n /* For general text */\r\n}\r\n\r\n\r\nh1, h2, h3 {\r\n    font-family: \"Open Sans\",serif; /* Stylish headings */\r\n    font-weight: 800;\r\n    font-style: oblique;\r\n    color: #333;\r\n}\r\n\r\n.project-container{\r\n   \r\n    padding: 20px;\r\n    border-radius: 20px;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
