// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset styles for consistent rendering */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

/* body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} */

/* form {
  max-width: 800px;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}



input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

.error {
  color: #d9534f;
  font-size: 0.875rem;
  margin-top: 0.25rem;
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;;;;;;;;GAQG;;AAEH;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA+BG","sourcesContent":["/* Reset styles for consistent rendering */\r\nbody, html {\r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: Arial, sans-serif;\r\n  box-sizing: border-box;\r\n}\r\n\r\n/* body {\r\n  font-family: Arial, sans-serif;\r\n  color: #333;\r\n  background-color: #f9f9f9;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 100vh;\r\n} */\r\n\r\n/* form {\r\n  max-width: 800px;\r\n  padding: 2rem;\r\n  background-color: #fff;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  border-radius: 8px;\r\n}\r\n\r\n.form-group {\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\nlabel {\r\n  display: block;\r\n  font-weight: bold;\r\n  margin-bottom: 0.5rem;\r\n  color: #555;\r\n}\r\n\r\n\r\n\r\ninput:focus,\r\nselect:focus {\r\n  border-color: #007bff;\r\n  outline: none;\r\n}\r\n\r\n.error {\r\n  color: #d9534f;\r\n  font-size: 0.875rem;\r\n  margin-top: 0.25rem;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
