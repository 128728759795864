import React, { useEffect, useState } from "react";
import "./Freelancer.css";
import { MdNotificationsActive } from "react-icons/md";
import companyLogo from "../../../Assets/logos/VIGHNAAORESS.png";
import { FaUserLarge } from "react-icons/fa6";
import { GiWallet } from "react-icons/gi";
import { Link } from "react-router-dom";
import Logout from "../../Auth/Logout/Logout";
import axios from "axios";
import ProjectList from "./ProjectList/ProjectList";
import DashboardSidenav from "./DashboardNav.js/DashboardSidenav";
import CurrencyDropdown from "./Currencydropdown/CurrencyDropdownButton";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import NotificationCount from "./Notification/NotificationBell";
import ChatbotButton from "../../../Components/ChatBotButton/ChatbotButton";
import { projectForm_API_URL } from "../../../utils/constants";
import UserDropdown from "./UserDropdown/UserDropdown";
const Freelancer = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 5; // Set limit per page
  const username= localStorage.getItem("username");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(projectForm_API_URL);
        setProjects(response.data);
        console.log(response.data);
      } catch (err) {
        setError("Failed to load projects.");
      }
    };
    fetchProjects();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(projects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="dashboard-container">
        {/* Navbar */}
        <header className="navbar">
          <div className="navbar-logo">
            <img
              src={companyLogo}
              alt="logo"
              style={{ display: "block", maxWidth: "12%", height: "auto" }}
            />
          </div>
          <ul className="navbar-links">
            {/* <li>
            <a href="#dashboard">Dashboard</a>
          </li>*/}
            <li>
              <CurrencyDropdown />
            </li>
            <li>
              {" "}
              <Link to={`/wallet`}>
                {" "}
                <GiWallet
                  style={{
                    fontSize: "40px",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </li>
            <li>
              {/* <MdNotificationsActive
                style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              /> */}
              <NotificationCount />
            </li>

            <li>
              <Logout />
            </li>
            {/* <li>
              <Link to="/userprofile">
                {" "}
                <FaUserLarge
                  style={{
                    fontSize: "40px",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </li> */}

<li>
  <UserDropdown username={username} />
</li>

          </ul>
        </header>
        <div className="content-container">
          {/* Side Navigation */}
          <DashboardSidenav />
          {/* Main Content */}
          <main className="main-content">
            <div
              className="project-card"
              style={{ margin: "1rem", padding: "0.5rem" }}
            >
              <ul>
                {currentProjects.map((project) => {
                  return (
                    <div
                      key={project.id}
                      style={{
                        background:
                          " linear-gradient(110deg, rgba(34,193,195,1) 0%, rgba(253,230,45,1) 100%)", // Semi-transparent white
                        backdropFilter: "blur(10px)", // Blur effect
                        WebkitBackdropFilter: "blur(10px)", // For Safari support
                        borderRadius: "20px",
                        border: "1px solid rgba(255, 255, 255, 0.3)", // Subtle border
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow
                        color: "black",
                        marginTop: "1rem",
                        padding: "1.5rem",
                      }}
                    >
                      <ProjectList
                        projectId={project.id}
                        projectName={project.projectName}
                        projectDescription={project.projectDescription}
                        projectSkill={project.projectSkill}
                        projectAmount={project.amount}
                        currencyType={project.currencyType}
                      />
                      <button
                        onClick={() =>
                          navigate(`/invoice/${project.id}`, {
                            state: { project },
                          })
                        }
                        style={styles.invoiceButton}
                      >
                        📄 Download Invoice
                      </button>
                    </div>
                  );
                })}
              </ul>
              <ChatbotButton/>
            </div>
            {/* Pagination Component */}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </main>
        </div>
      </div>
    </div>
  );
};

const styles = {
  invoiceButton: {
    backgroundColor: "#4CAF50", // Green
    color: "white",
    padding: "12px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "0.3s ease",
    marginTop: "20px",
  },
};

export default Freelancer;
