import React, { useEffect, useState } from "react";
import "../Organization/OrganizationStyles.css";
import { getProjects } from "../../utils/constants";
import { Link } from "react-router-dom";
import ProjectCard from "./ProjectCard/ProjectCard";
import Pagination from "../Home/Freelancer/Pagination/Pagination";
import Navbar from "../Home/Freelancer/Navbar/Navbar";


const OrganizationDashboard = () => {
  const [projects, setProjects] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 5; // Set limit per page

  const organizationId = localStorage.getItem("organizationId");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (organizationId) {
          const data = await getProjects(organizationId);
          console.log("getting data from projects ||", data);
          setProjects(data); // Save the projects in state
        } else {
          console.error("Organization ID not found");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [organizationId]);

  useEffect(() => {
    const logo = document.querySelector(".navbar-logo img");
    if (logo) {
      logo.style.opacity = 0;
      logo.style.transform = "translateX(-200px)";
      setTimeout(() => {
        logo.style.transition = "opacity 0.6s ease-out, transform 0.6s ease-out";
        logo.style.opacity = 1;
        logo.style.transform = "translateX(0)";
      }, 300);
    }
  }, []);


  // Pagination logic
  const totalPages = Math.ceil(projects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="organizationdashboard-container">
      {/* Navbar */}
     
        <Navbar />
     
      <div className="content-container">
        {/* Side Navigation */}
        <nav className="organization-sidenav">
          <ul>
            <li>
              <Link to={"/home"}>Home</Link>
            </li>
            <li>
              <Link to={"/organization"}>Post Project</Link>
            </li>
            <li>
              <Link to="/organizationInfo">About organization</Link>
            </li>
          </ul>
        </nav>
        {/* Main Content */}
        <main className="main-content">
          {currentProjects.length > 0 ? (
            <ul>
              {projects.map((project) => (
                <li key={project.id}>
                  <ProjectCard project={project} />
                </li>
              ))}
            </ul>
          ) : (
            <div>No projects available for this organization.</div> // No projects state
          )}
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
        </main>
      </div>
    </div>
  );
};

export default OrganizationDashboard;
