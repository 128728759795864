import { io } from "socket.io-client";

const socket = io("http://localhost:5000", {
    transports: ["websocket"], // Ensure WebSocket transport
  });
  
  socket.on("connect", () => {
    console.log("Connected to Socket.io server");
  });
  
  socket.on("receiveMessage", (data) => {
    console.log("Message received:", data);
  });
export default socket;
