import React from "react";
import { useForm } from "react-hook-form";
import { signup } from "../../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import "../signup/SignupStyles.css";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const formData = {
        username: data.Username,
        password: data.Password,
        role: data.Role,
      };
      const response = await signup(formData);
      alert("Registration successful");
      navigate("/signin");
      const userId = response.data.userId;
      if (formData.role === "organization")
        return navigate(`/organizationRegister/${userId}`);
    } catch (error) {
      alert("Registration failed");
      console.error(error.response);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="form-title">Sign Up</h2>

        {/* Username Input */}
        <div className="input-group">
          <label>Email</label>
          <input
            type="text"
            name="Username"
            className="input-field"
            {...register("Username", {
              required: "Username is required",
              minLength: { value: 3, message: "At least 3 characters required" },
            })}
          />
          {errors.Username && <p className="error-text">{errors.Username.message}</p>}
        </div>

        {/* Password Input */}
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            name="Password"
            className="input-field"
            {...register("Password", {
              required: "Password is required",
              minLength: { value: 3, message: "At least 3 characters required" },
            })}
          />
          {errors.Password && <p className="error-text">{errors.Password.message}</p>}
        </div>

        {/* Role Dropdown */}
        <div className="input-group">
          <label>Role</label>
          <select
            name="Role"
            className="input-field"
            {...register("Role", { required: "Role is required" })}
          >
            <option value="" >Select a Role</option>
            <option value="freelancer" >Freelancer</option>
            {/* <option value="admin" style={{ fontFamily: "velocista"}}>Admin</option> */}
            <option value="organization" >Organization</option>
          </select>
          {errors.Role && <p className="error-text">{errors.Role.message}</p>}
        </div>

        {/* Signup Button */}
        <div className="button-group">
          <button type="submit" className="signup-btn">
            Signup <FaArrowAltCircleRight className="icon" />
          </button>
        </div>

        {/* Login Link */}
        <p className="signup-text">
          Already a member? <Link to="/signin" className="signup-link">Login here</Link>
        </p>
      </form>
    </div>
  );
};

export default Signup;
